import React from 'react';
import {
    ReferenceLine,
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
    Text,
    Label,
    PieChart,
    Pie,
} from "recharts";
import { chartColors, titles } from '../FilledStatsConsts';

const ScalesBar = ({ category, data }) => {

    const renderCustomizedLabel2 = (props) => {
        const { x, y, width, height, value } = props;
        return (
            <g>
                <text
                    x={x + width / 2}
                    y={y + height / 2}
                    fill="#fff"
                    textAnchor="middle"
                    dominantBaseline="middle"
                >
                    {props.value != "0" ? `${parseInt(value)}` : ""}
                </text>
            </g>
        );
    };
    console.log('category', category)
    console.log('data', data)
    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={800}
                height={300}
                data={[data]}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Legend />
                {category.map((option, index) => (
                    <Bar dataKey={titles[option]} fill={chartColors[index]}>
                        <LabelList
                            dataValues={[data]}
                            content={renderCustomizedLabel2}
                        />
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default ScalesBar;