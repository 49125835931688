import React from 'react';
import { ReferenceLine, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList, Text } from 'recharts';
import { subscaleMaxValue, chartColors, titles, leaders, everybody } from './FilledStatsConsts';
import { CSVLink } from "react-csv";
import { MoreVert } from '@material-ui/icons';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

const createTszkIndex = (value) => {
    let v = value;
    let index = 'Nincs tudatában';
    switch (true) {
        case (v < 20):
            index = 'Nincs tudatában';
            break;
        case (v < 40):
            index = 'Tudatában van';
            break;
        case (v < 60):
            index = 'Megérti';
            break;
        case (v < 80):
            index = 'Elhiszi';
            break;
        case (v <= 100):
            index = 'Cselekszik';
            break;
        default:
            index = 'Cselekszik';
    }
    return index;
}


const TszkIndex = (params) => {
    // console.log('TSZK params', params);
    const { title = "TSZK Index", data, dataKey, height, yAxisWidth, order } = params;

    // Rendezzük az adatokat az `order` paraméter alapján
    const sortedData = [...data].sort((a, b) => {
        if (order === "name") {
            return a.name.localeCompare(b.name);
        } else if (order === "value") {
            return (b[dataKey ?? "TSZK Index"] || 0) - (a[dataKey ?? "TSZK Index"] || 0);
        }
        return 0;
    });
    const renderCustomizedLabel2 = (props) => {
        const { x, y, width, height, value } = props;
        return (
            <g>
                <text x={x + width / 2} y={y + 11} fill="#333" textAnchor="middle" dominantBaseline="middle">
                    {props.value != '0' ? `${createTszkIndex(props.value)} (${parseInt(value)}%)` : ''}
                </text>
            </g>
        );
    }

    // Menu state management
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const convertToCsvFormat = (data) => {
        if (!Array.isArray(data) || data.length === 0) {
            return [];
        }

        // Extract headers from the first object's keys
        const headers = Object.keys(data[0]);

        // Map data values into arrays
        const rows = data.map(item => headers.map(header => item[header] ?? ''));

        // Combine headers and rows
        return [headers, ...rows];
    };
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3>{title}</h3>
                {/* Menu Button */}
                <IconButton
                    aria-label="settings"
                    onClick={handleMenuOpen}
                // style={{ position: 'absolute', top: 10, right: 10 }}
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MenuItem onClick={handleMenuClose}>
                        <CSVLink data={convertToCsvFormat(sortedData)} filename="export.csv" style={{ textDecoration: 'none', color: 'inherit' }}>
                            CSV export
                        </CSVLink>
                    </MenuItem>
                </Menu>
            </div>
            <ResponsiveContainer width="100%" height={height ?? 300}>
                <BarChart
                    // width={1800}
                    // height={300}
                    data={sortedData}
                    layout="vertical"
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" domain={[0, 100]} />
                    {/* <XAxis dataKey="name" interval={0} rotate={30}/> */}
                    {/* <XAxis tick={<CustomizedTick />} /> */}
                    <YAxis dataKey="name" interval={0} type="category"
                        // tickMargin={30}
                        width={yAxisWidth ?? 150}
                    />
                    <Tooltip />
                    <Legend />
                    <ReferenceLine x="20" stroke="red" label="" />
                    <ReferenceLine x="40" stroke="red" label="" />
                    <ReferenceLine x="60" stroke="red" label="" />
                    <ReferenceLine x="80" stroke="red" label="" />
                    <Bar dataKey={dataKey ?? "TSZK Index"} fill={chartColors[0]} minPointSize={5}>
                        <LabelList dataKey={dataKey ?? "TSZK Index"} dataValues={data} content={renderCustomizedLabel2} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};

export default TszkIndex;