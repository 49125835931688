import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import {
    leaders, everybody, allFields, subscales, scales, leadersCategories, years, positions, leadersCategories0,
} from "./FilledStatsConsts";
import { createBaseData, groupingData } from "./FilledStatsUtility";
import InfiniteScrollList from "./charts/InfiniteScrollList";
import ActualInstitute from './ActualInstitute';

const FilledStatsData3 = ({
    model,
    classes,
    questionnaire,
    institutions,
    dataLoading,
    actualInstitution,
    admins
}) => {
    const [base, setBase] = useState();
    const [allData, setAllData] = useState();
    const [dataForAllChart, setDataForAllChart] = useState();
    const [charts, setCharts] = useState();
    const category = admins ? admins.map(i => ({ value: institutions[i].name, label: institutions[i].name })) : [];

    const calculateData = (model, classes, questionnaire, institutions, dataLoading) => {
        if (!dataLoading && model && questionnaire && institutions) {
            let base = [];
            let allData = [];
            let dataForAllChart = [];
            base = createBaseData(model, questionnaire, institutions);

            allData = groupingData(
                base,
                "position_txt",
                allFields,
                everybody
            );

            dataForAllChart = [
                {
                    name: "Összes",
                    index:
                        allData && allData.mean && allData.mean.everybody
                            ? allData.mean.everybody.percent
                            : 0,
                },
            ];
            setBase(base);
            setAllData(allData);
            setDataForAllChart(dataForAllChart);
            setCharts([
                {
                    type: "TszkBarChart2",
                    title: "Intézményenként",
                    data: base,
                    categoryField: "institution",
                    category: category,
                    rotate: true,
                    order: "value",
                    height: 150 + Object.keys(category).length * 45

                }
            ]);
        }
    };

    useEffect(() => {
        calculateData(
            model, classes, questionnaire, institutions, dataLoading
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model]);

    return (
        <>
            {charts && base && allData &&
                dataForAllChart &&
                (
                    <>
                        <h1>Intézmények</h1>
                        {/* <ActualInstitute
                            institutionsArray={institutions}
                            actualInstitution={actualInstitution}
                        /> */}
                        <h3>Kitöltők száma: {base ? base.length : "0"}</h3>
                        <InfiniteScrollList
                            items={charts}
                        />
                        <CSVLink data={base}>CSV export</CSVLink>
                    </>
                )}
        </>
    );
};

export default FilledStatsData3;