import React from 'react';

const CustomizedAxisTick2 = (props) => {
    const shortName = {
        'Ön- és továbbképzés %': 'Önképzés',
        'Proaktivitás %': 'Proaktivitás',
        'Bizalom és nyitottság %': 'Bizalom',
        'Reflektivitás %': 'Reflektivitás.',
        'Tudásmenedzsment %': 'Tudásmenedzsm.',
        'Támogatás és felhatalmazás %': 'Támogatás',
        'Tanuló- és tanulásközpontú jövőkép %': 'Jövőkép',
        'Rendszergondolkodás %': 'Rendszergond.',
        'Hálózatépítés és -fenntartás %': 'Hálózatépítés',
        'Emberi kapacitás %': 'Emberi kapacitás',
        'Interperszonális kapacitás %': 'Interperszonális kapacitás',
        'Szervezeti kapacitás %': 'Szervezeti kapacitás',
        'Hálózati kapacitás %': 'Hálózati kapacitás',
        'vezető': 'vezető',
        'nem vezető': 'nem vezető',
    }
    const { x, y, width, height, value, payload } = props;

    return (
        <text width={100} x={x} y={y + 11} fill="#000" textAnchor="middle" dominantBaseline="middle">
            <tspan textAnchor="middle">{shortName[payload.value]}</tspan>
        </text>
    )
};
export default CustomizedAxisTick2;