import React, { useEffect, useState } from 'react';
import {
    ReferenceLine,
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
    Text,
    Label,
    PieChart,
    Pie,
} from "recharts";
import {
    chartColors,
    titles,
    everybody,
    allFields,
} from "../FilledStatsConsts";
import CustomizedAxisTick2 from '../CustomizedAxisTick2';
import { grouping, grouping2 } from './Utility';
import groupingData from '../FilledStatsUtility';

const renderCustomizedLabel2 = (props) => {
    const { x, y, width, height, value } = props;
    return (
        <g>
            <text
                x={x + width / 2}
                y={y + height / 2}
                fill="#fff"
                textAnchor="middle"
                dominantBaseline="middle"
            >
                {props.value != "0" ? `${parseInt(value)}` : ""}
            </text>
        </g>
    );
};
const GeneralChart = ({
    title,
    category,
    series,
    questionnaire,
    data,
    seriesGrouping,
    rotate = false
}) => {
    const [chartData, setChartData] = useState();

    useEffect(() => {

        const groupByLeader = groupingData(
            data,
            "position_txt",
            category,
            seriesGrouping
        );
        // console.log('groupByLeader', groupByLeader);

        let dataForLeaderColumns3Chart = [];
        if (questionnaire && groupByLeader.sum) {
            if (rotate) {
                dataForLeaderColumns3Chart = grouping2({
                    questionnaire: questionnaire,
                    baseData: groupByLeader,
                    category: series,
                    series: category,
                    titles: titles,
                })
            } else {
                dataForLeaderColumns3Chart = grouping({
                    questionnaire: questionnaire,
                    baseData: groupByLeader,
                    category: category,
                    series: series,
                    titles: titles,
                })
            }
            setChartData(dataForLeaderColumns3Chart);
        }
        // console.log('data', dataForLeaderColumns3Chart);
    }, [questionnaire, data]);

    return (
        <div>
            <h3>
                {title}
            </h3>

            {renderCustomizedLabel2 && chartData &&
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        width={800}
                        height={300}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            tick={<CustomizedAxisTick2 />}
                            interval={0}
                            dataKey="name"
                        />
                        <YAxis domain={[0, 100]} />
                        <Tooltip />
                        <Legend />
                        {rotate && category.map((option, index) => (
                            <Bar key={index} dataKey={titles[option]} fill={chartColors[index]}>
                                <LabelList content={renderCustomizedLabel2} />
                            </Bar>
                        ))}
                        {!rotate && series.map((option, index) => (
                            <Bar key={index} dataKey={titles[option]} fill={chartColors[index]}>
                                <LabelList content={renderCustomizedLabel2} />
                            </Bar>
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            }

        </div>
    );
};

export default GeneralChart;