import { allFields, titles } from "../FilledStatsConsts";

export const grouping = ({
    questionnaire,
    baseData,
    category,
    series,
    titles
}) => {

    let data = [];
    if (questionnaire && baseData.sum) {
        category.forEach((field1) => {
            let results = [];
            let tfield1 = titles[field1];
            series.forEach((field2) => {
                let tfield2 = titles[field2];
                results[tfield2] =
                    baseData["mean"] &&
                        baseData["mean"][tfield2] &&
                        baseData["mean"][tfield2][field1]
                        ? baseData["mean"][tfield2][field1]
                        : 0;
            });
            results["name"] = tfield1;
            data.push(results);
        });
        ;
    }
    return data;
}

export const grouping2 = ({
    questionnaire,
    baseData,
    category,
    series,
    titles
}) => {
    let data = [];
    if (questionnaire && baseData.sum) {
        category.forEach((field1) => {
            let results = [];
            let tfield1 = titles[field1];
            series.forEach((field2) => {
                let tfield2 = titles[field2];
                results[tfield2] =
                    baseData["mean"] &&
                        baseData["mean"][tfield1] &&
                        baseData["mean"][tfield1][field2]
                        ? baseData["mean"][tfield1][field2]
                        : 0;
            });
            results["name"] = tfield1;
            data.push(results);
        });
        ;
    }
    return data;
}

export const grouping3 = ({
    category,
    baseData
}) => {
    let data = [];
    if (category && baseData.sum) {
        Object.keys(category.values).forEach((q) => {
            let element = [];
            element["name"] = category.values[q]["label"];
            element["Kitöltők száma"] = baseData["sum"][category.values[q]["label"]]
                ? baseData["sum"][category.values[q]["label"]]["db"] : 0;
            element["TSZK Index"] = baseData["sum"][category.values[q]["label"]]
                ? baseData["mean"][category.values[q]["label"]]["percent"] : 0;
            data.push(element);
        });
    }
    return data;
}
export const grouping5 = ({
    category,
    baseData
}) => {
    let data = [];
    if (category && baseData.sum) {
        Object.keys(category).forEach((q) => {
            let element = [];
            element["name"] = category[q]["label"];
            element["Kitöltők száma"] = baseData["sum"][category[q]["label"]]
                ? baseData["sum"][category[q]["label"]]["db"] : 0;
            element["TSZK Index"] = baseData["sum"][category[q]["label"]]
                ? baseData["mean"][category[q]["label"]]["percent"] : 0;
            data.push(element);
        });
    }
    return data;
}

export const grouping4 = ({
    category,
    baseData
}) => {
    let data = [];
    if (category && baseData) {
        category.forEach((field) => {
            let results = [];
            let tfield = titles[field];

            allFields.forEach((value) => {
                results[titles[value]] =
                    baseData &&
                        baseData[tfield] &&
                        baseData[tfield][value]
                        ? baseData[tfield][value]
                        : 0;
            });
            results["name"] = tfield;
            results["Kitöltők száma"] = baseData[tfield] ? baseData[tfield]["db"] : 0;
            results["TSZK Index"] = baseData[tfield] ? baseData[tfield]["percent"] : 0;
            data.push(results);
        });
    }
    return data;
}

export const grouping6 = ({
    category,
    baseData,
    field = "percent",
    stat = "mean"
}) => {
    let data = [];
    if (category && baseData.sum) {
        Object.keys(category).forEach((q) => {
            let element = [];
            element["name"] = category[q]["label"];
            element["Kitöltők száma"] = baseData["sum"][category[q]["label"]]
                ? baseData["sum"][category[q]["label"]]["db"] : 0;
            element["TSZK Index"] = baseData[stat][category[q]["label"]]
                ? baseData[stat][category[q]["label"]][field] : 0;
            data.push(element);
        });
    }
    return data;
}
export const grouping7 = ({
    category,
    baseData,
    field = "percent",
    stat = "mean"
}) => {
    let data = [];
    if (category && baseData.sum) {
        Object.keys(category).forEach((q) => {
            let element = [];
            element["name"] = category[q]["label"];
            element["TSZK Index"] =
                baseData &&
                    baseData[stat] &&
                    baseData[stat][field] &&
                    baseData[stat][field] &&
                    category &&
                    category[q] &&
                    category[q]["value"]
                    ? baseData[stat][field][category[q]["value"]] : 0;
            data.push(element);
        });
    }
    return data;
}