import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { chartColors } from './FilledStatsConsts';

const Chart1 = ({ chartData, rotate, categories, dataKey, order }) => {
    // Rendezzük az adatokat az `order` paraméter alapján
    const sortedData = [...chartData].sort((a, b) => {
        if (order === "name") {
            return a.name.localeCompare(b.name);
        } else if (order === "value") {
            return (b[dataKey ?? "Kitöltők száma"] || 0) - (a[dataKey ?? "Kitöltők száma"] || 0);
        }
        return 0;
    });

    const CustomizedAxisTick = (props) => {
        const shortName = Object.assign({
            "kevesebb, mint 1 éve": "<1 év",
            "1-3 éve": "1-3 éve",
            "4-6 éve": "4-6 éve",
            "7-9 éve": "7-9 éve",
            "10-12 éve": "10-12 éve",
            "13-15 éve": "13-15 éve",
            "16-18 éve": "16-18 éve",
            "18-21 éve": "18-21 éve",
            "22-24 éve": "22-24 éve",
            "több mint 25 éve": ">25 év",
            "igazgató, intézményegység vezető": "igazgató",
            "igazgató-helyettes, intézményegység-helyettes": "ig.-helyettes",
            "igazgató": "igazgató",
            "igazgató-helyettes": "ig.-helyettes",
            "osztályfőnök": "osztályfő.",
            "munkaközösség-vezető": "m.vezető",
            "nem oktató munkatárs": "nem okt.",
            "nem vezető beosztású pedagógus": "pedagógus",
            "egyéb, éspedig:": "egyéb",
        }, categories);

        const { x, y, value, payload } = props;
        const originalText = shortName[payload.value];
        const displayedText =
            originalText && originalText.length > 20
                ? `${originalText.substring(0, 20)}...`
                : originalText;
        return (
            <text
                x={x}
                y={y + 11}
                fill="#000"
                textAnchor="end"
                dominantBaseline="middle"
                transform={rotate ? `rotate(-10, ${x}, ${y + 10})` : ``}
            >
                <tspan textAnchor="middle">{displayedText}</tspan>
            </text>
        );
    };

    const renderCustomizedLabel2 = (props) => {
        const { x, y, width, height, value } = props;
        return (
            <g>
                <text x={x + width / 2} y={y + height / 2} fill="#fff" textAnchor="middle" dominantBaseline="middle">
                    {value !== '0' ? `${parseInt(value)}` : ''}
                </text>
            </g>
        );
    };

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                data={sortedData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="name"
                    interval={0}
                    tick={CustomizedAxisTick}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey={dataKey ?? "Kitöltők száma"} fill={chartColors[0]}>
                    <LabelList dataKey={dataKey ?? "Kitöltők száma"} content={renderCustomizedLabel2} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default Chart1;
