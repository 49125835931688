import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import useStyles from "../dashboard/styles";
import { useDispatch } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import { MenuItem, TextField } from '@material-ui/core';
import { setInstitution } from '../../../store/actions/menuActions';

const InstitutionSelect = ({ actualInstitution, admins, institutions, authStatus, addInstitution, updateInstitution, deleteInstitution, classes }) => {
    // const classes = useStyles();
    const dispatch = useDispatch();
    useFirestoreConnect([
        {
            collection: "institutions",
            storeAs: 'institutions',
        },
        {
            collection: "admins",
            doc: authStatus.uid,
            storeAs: 'admins'
        }
    ]);

    const handleSelectInsitution = (event) => {
        dispatch({ type: actions.SET_INSTITUTION, payload: event.target.value })
    };

    if (
        ((actualInstitution === '' && actualInstitution !== 'Összes intézmény') &&
            admins && admins[0] &&
            admins[0].institution &&
            admins[0].institution.length > 0
        ) ||
        ((actualInstitution === '' && actualInstitution !== 'Összes intézmény') &&
            admins && admins[0] &&
            admins[0].institution &&
            admins[0].institution.length > 0 &&
            admins[0].institution.indexOf(actualInstitution) < 0
        )) {
        dispatch({ type: actions.SET_INSTITUTION, payload: admins[0].institution[0] })
    }
    return (

        <TextField
            id="outlined-select-currency"
            // style={{ maxWidth: '30%' }}
            className={classes.eee}
            select
            // label="Select"
            value={actualInstitution}
            onChange={handleSelectInsitution}
            // helperText="Intézmény"
            InputProps={{
                classes: {
                    input: classes.eee
                }
            }}
        >
            <MenuItem key={"Összes intézmény"} value={"Összes intézmény"}>
                {"Összes intézmény"}
            </MenuItem>
            {institutions && institutions.map((option) => (
                admins && admins[0] && admins[0].institution && admins[0].institution.indexOf(option.id) > -1 &&
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>


            ))}
        </TextField>
    );
};

const mapStateToProps = (state) => {
    return {
        institutions: state.firestore.ordered.institutions,
        admins: state.firestore.ordered.admins,
        authStatus: state.firebase.auth,
        actualInstitution: state.questionnaire.institution,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInstitution: (institution) => dispatch(setInstitution(institution)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionSelect);
