import React, { useState, useCallback, useEffect } from "react";
import InfiniteScroll from "./InfiniteScroll";

import TszkIndex from "../TszkIndex";
import ScalesBar from "./ScalesBar";
import FilledPieChart from "./FilledPieChart";
import TszkBarChart from "../TszkBarChart";
import GeneralChart from "./GeneralChart";
import FilledTable from "./FilledTable";
import ScalesBar3 from "./ScalesBar3";
import TszkBarChart2 from "../TszkBarChart2";

const InfiniteScrollList = ({ items }) => {
    const [showItems, setShowItems] = useState(items.slice(0, 2));
    const [loading, setLoading] = useState(false);

    const fetchMoreData = useCallback(() => {
        if (showItems.length >= items.length || loading) {
            return;
        }

        setLoading(true);

        // Szimulált betöltés
        setTimeout(() => {
            setShowItems((prevItems) =>
                [...prevItems, ...items.slice(prevItems.length, prevItems.length + 2)]
            );
            setLoading(false);
        }, 10);
    }, [items, showItems, loading]);

    useEffect(() => {
        setShowItems(items.slice(0, 1));
    }, [items]);

    return (
        <div>
            {showItems.map((item, index) => (
                <MemoizedListItem key={index} item={item} />
            ))}
            {showItems.length < items.length && (
                <InfiniteScroll loadMore={fetchMoreData} />
            )}
            {loading && <p>Betöltés...</p>}
        </div>
    );
};

const ListItem = ({ item }) => (
    <>
        {
            item.type === 'TszkIndex' &&
            <TszkIndex
                title={item.title}
                data={item.data}
                height={item.height}
                yAxisWidth={item.yAxisWidth}
                dataKey={item.dataKey}
            />
        }
        {
            item.type === 'ScalesBar' &&
            <ScalesBar
                data={item.data}
                category={item.category}
            />
        }
        {
            item.type === 'ScalesBar3' &&
            <ScalesBar3
                data={item.data}
                category={item.category}
                stat={item.stat0}
            />
        }
        {
            item.type === 'FilledPieChart' &&
            <FilledPieChart
                data={item.data}
            />
        }
        {
            item.type === 'TszkBarChart' &&
            <TszkBarChart
                title={item.title}
                data={item.data}
                height={item.height}
                yAxisWidth={item.yAxisWidth}
            />
        }
        {
            item.type === 'TszkBarChart2' &&
            <TszkBarChart2
                title={item.title}
                data={item.data}
                height={item.height}
                yAxisWidth={item.yAxisWidth}
                categoryField={item.categoryField}
                category={item.category}
                subgroup={item.subgroup}
                order={item.order}
                rotate={item.rotate}
            />
        }
        {
            item.type === 'GeneralChart' &&
            <GeneralChart
                title={item.title}
                data={item.data}
                category={item.category}
                series={item.series}
                seriesGrouping={item.seriesGrouping}
                questionnaire={item.questionnaire}
                rotate={item.rotate}
            />
        }
        {
            item.type === 'FilledTable' && item.data &&
            <FilledTable
                title={item.title}
                data={item.data}
                classes={item.classes}
                base={item.base}
                // groupByYear={item.groupByYear}
                // groupByPosition={item.groupByPosition}
                // groupByLeader={item.groupByLeader}
                questionnaire={item.questionnaire}
            />
        }
    </>
);

const MemoizedListItem = React.memo(ListItem);

export default InfiniteScrollList;
