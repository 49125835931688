import { sum, mean, median } from "stats-lite"
import { subscaleMaxValue, chartColors, titles, leaders } from './FilledStatsConsts';

export const createBaseData = (model, questionnaire, institutions) => {

    return model.map((field, index) => (
        {
            id: field.id,
            date: field.createdAt.toDate().toLocaleDateString('fr-CA'),
            time: field.createdAt.toDate().toLocaleTimeString('en-EN'),
            subject: field.personal[1],
            years_value: field.personal[2],
            years_txt: questionnaire.personal[1].values[field.personal[2] - 1].label,
            position_value: field.personal[3],
            position_txt: questionnaire.personal[2].values[field.personal[3] - 1].label,
            etc: field.personal[4] ? field.personal[4] : '',
            // institution: field.personal[5][0],
            institution: institutions[field.institutions[0]].name,

            value: field.evaluations[1].value +
                field.evaluations[2].value +
                field.evaluations[3].value +
                field.evaluations[4].value,

            percent: parseInt(100 * (field.evaluations[1].value +
                field.evaluations[2].value +
                field.evaluations[3].value +
                field.evaluations[4].value) / 80),

            scale1_value: field.evaluations[1].value,
            scale1_evaluation_value: field.evaluations[1].evaluation.value,
            scale1_evaluation_name: field.evaluations[1].evaluation.name,

            scale2_value: field.evaluations[2].value,
            scale2_evaluation_value: field.evaluations[2].evaluation.value,
            scale2_evaluation_name: field.evaluations[2].evaluation.name,

            scale3_value: field.evaluations[3].value,
            scale3_evaluation_value: field.evaluations[3].evaluation.value,
            scale3_evaluation_name: field.evaluations[3].evaluation.name,

            scale4_value: field.evaluations[4].value,
            scale4_evaluation_value: field.evaluations[4].evaluation.value,
            scale4_evaluation_name: field.evaluations[4].evaluation.name,

            scale1_percent: parseInt(100 * field.evaluations[1].value / 20),
            scale2_percent: parseInt(100 * field.evaluations[2].value / 20),
            scale3_percent: parseInt(100 * field.evaluations[3].value / 20),
            scale4_percent: parseInt(100 * field.evaluations[4].value / 20),

            subscale1_value: field.evaluations[1].sub_scales[1].value,
            subscale2_value: field.evaluations[1].sub_scales[2].value,
            subscale3_value: field.evaluations[2].sub_scales[1].value,
            subscale4_value: field.evaluations[2].sub_scales[2].value,
            subscale5_value: field.evaluations[3].sub_scales[1].value,
            subscale6_value: field.evaluations[3].sub_scales[2].value,
            subscale7_value: field.evaluations[3].sub_scales[3].value,
            subscale8_value: field.evaluations[4].sub_scales[1].value,
            subscale9_value: field.evaluations[4].sub_scales[2].value,

            subscale1_percent: parseInt(100 * field.evaluations[1].sub_scales[1].value / subscaleMaxValue[0]),
            subscale2_percent: parseInt(100 * field.evaluations[1].sub_scales[2].value / subscaleMaxValue[1]),
            subscale3_percent: parseInt(100 * field.evaluations[2].sub_scales[1].value / subscaleMaxValue[2]),
            subscale4_percent: parseInt(100 * field.evaluations[2].sub_scales[2].value / subscaleMaxValue[3]),
            subscale5_percent: parseInt(100 * field.evaluations[3].sub_scales[1].value / subscaleMaxValue[4]),
            subscale6_percent: parseInt(100 * field.evaluations[3].sub_scales[2].value / subscaleMaxValue[5]),
            subscale7_percent: parseInt(100 * field.evaluations[3].sub_scales[3].value / subscaleMaxValue[6]),
            subscale8_percent: parseInt(100 * field.evaluations[4].sub_scales[1].value / subscaleMaxValue[7]),
            subscale9_percent: parseInt(100 * field.evaluations[4].sub_scales[2].value / subscaleMaxValue[8]),

            answer1: field.answers[1],
            answer2: field.answers[2],
            answer3: field.answers[3],
            answer4: field.answers[4],
            answer5: field.answers[5],
            answer6: field.answers[6],
            answer7: field.answers[7],
            answer8: field.answers[8],
            answer9: field.answers[9],
            answer10: field.answers[10],
            answer11: field.answers[11],
            answer12: field.answers[12],
            answer13: field.answers[13],
            answer14: field.answers[14],
            answer15: field.answers[15],
            answer16: field.answers[16],
            answer17: field.answers[17],
            answer18: field.answers[18],
            answer19: field.answers[19],
            answer20: field.answers[20],
            answer21: field.answers[21],
            answer22: field.answers[22],
            answer23: field.answers[23],
            answer24: field.answers[24],
            answer25: field.answers[25],
            answer26: field.answers[26],
            answer27: field.answers[27],
            answer28: field.answers[28],
            answer29: field.answers[29],
            answer30: field.answers[30],
            answer31: field.answers[31],
            answer32: field.answers[32],
            answer33: field.answers[33],
            answer34: field.answers[34],
            answer35: field.answers[35],
            answer36: field.answers[36],
            answer37: field.answers[37],
            answer38: field.answers[38],
            answer39: field.answers[39],
            answer40: field.answers[40],
            answer41: field.answers[41],
            answer42: field.answers[42],
            answer43: field.answers[43],
            answer44: field.answers[44],
            answer45: field.answers[45],
            answer46: field.answers[46],
            answer47: field.answers[47],
            answer48: field.answers[48],
            answer49: field.answers[49],
            answer50: field.answers[50],
            answer51: field.answers[51],
            answer52: field.answers[52],
            answer53: field.answers[53],
            answer54: field.answers[54],
            answer55: field.answers[55],
            answer56: field.answers[56],
            answer57: field.answers[57],
            answer58: field.answers[58],
            answer59: field.answers[59],
            answer60: field.answers[60],
            answer61: field.answers[61],
            answer62: field.answers[62],
            answer63: field.answers[63],
            answer64: field.answers[64],
            answer65: field.answers[65],
            answer66: field.answers[66],
            answer67: field.answers[67],
            answer68: field.answers[68],
            answer69: field.answers[69],
            answer70: field.answers[70],
            answer71: field.answers[71],
            answer72: field.answers[72],
            answer73: field.answers[73],
            answer74: field.answers[74],
            answer75: field.answers[75],
            answer76: field.answers[76],
            answer77: field.answers[77],
            answer78: field.answers[78],
            answer79: field.answers[79],
            answer80: field.answers[80],
        }
    ))
}
export const evaluate = (filled, questionnaire) => {
    // console.log('filled', filled)
    let result = {};
    let params = [];

    questionnaire.scales.forEach((scale, index) => {
        let value = parseInt(filled[index]);
        // evaluation scales
        let sub_result = {};
        let evaluation = {};
        scale.evaluation.forEach(e => {
            // console.log('e', e);
            // console.log('index', index);
            // console.log('filled-index', value)
            if (e.min <= value && value <= e.max) {
                evaluation = { ...e };
            }
        });
        result[scale.id] = {
            value: value,
            evaluation: evaluation,
            sub_scales: sub_result
        };
    });
    // console.log('EVALUATION.....', result);
    return (result);
    // updateEvaluations(result, filled.id)
};

export const groupingData = (data, groupByField, groupingFields, subGroup = null) => {
    let number = 0;
    let result = [];
    data.map((row, index) => {
        groupingFields.map((groupingField) => {
            let groupByFieldValue = subGroup == null ? row[groupByField] : subGroup[row[groupByField]];
            if (!result[groupByFieldValue]) {
                result[groupByFieldValue] = [];
            }
            if (!result[groupByFieldValue][groupingField]) {
                result[groupByFieldValue][groupingField] = [];
            }
            result[groupByFieldValue][groupingField].push(row[groupingField])
        });
        number += 1;
    });

    let data_sum = [];
    let data_mean = [];
    let data_median = [];
    data.map((row, index) => {
        groupingFields.map((groupingField) => {
            let groupByFieldValue = subGroup == null ? row[groupByField] : subGroup[row[groupByField]];
            if (!data_sum[groupByFieldValue]) {
                data_sum[groupByFieldValue] = [];
                data_mean[groupByFieldValue] = [];
                data_median[groupByFieldValue] = [];
            }
            data_sum[groupByFieldValue][groupingField] = sum(result[groupByFieldValue][groupingField]);
            data_mean[groupByFieldValue][groupingField] = mean(result[groupByFieldValue][groupingField]);
            data_median[groupByFieldValue][groupingField] = median(result[groupByFieldValue][groupingField]);

            data_sum[groupByFieldValue] = {
                ...data_sum[groupByFieldValue],
                name: groupByFieldValue,
                db: result[groupByFieldValue][groupingField].length
            };
            data_mean[groupByFieldValue] = {
                ...data_mean[groupByFieldValue],
                name: groupByFieldValue,
                db: result[groupByFieldValue][groupingField].length
            };
        });
    });
    
    return {
        number: number,
        result: result,
        sum: data_sum,
        mean: data_mean,
        median: data_median,
    }
}

export default groupingData