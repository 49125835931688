import React, { useEffect, useState } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
} from "recharts";
import { chartColors, titles } from '../FilledStatsConsts';
import { MenuItem, TextField } from '@material-ui/core';

const ScalesBar3 = ({ category, data, stat = 'mean' }) => {
    const [chartData, setChartData] = useState();
    const [stat2, setStat] = useState(stat);

    const renderCustomizedLabel2 = (props) => {
        const { x, y, width, height, value } = props;
        return (
            <g>
                <text
                    x={x + width / 2}
                    y={y + height / 2}
                    fill="#fff"
                    textAnchor="middle"
                    dominantBaseline="middle"
                >
                    {props.value != "0" ? `${parseInt(value)}` : ""}
                </text>
            </g>
        );
    };

    useEffect(() => {
        let scaleChartData = [];
        scaleChartData["name"] = "Sklálák";
        scaleChartData["name2"] = "Alsklálák";

        category.forEach((key) => {
            scaleChartData[titles[key]] =
                data && data[stat2] && data[stat2].everybody
                    ? data[stat2].everybody[key]
                    : 0;
        });
        // console.log('scaleChartData', scaleChartData);
        setChartData(scaleChartData);
    }, [category, data, stat2]);

    const handleChange2 = (e) => {
        e.preventDefault();
        setStat(e.target.value);
    };
    // console.log('stat2', stat2)
    // console.log('data', data)
    return (<>

        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span>Adat:</span>
            <TextField
                required
                id={"distribution2"}
                value={stat2}
                name={"distribution2"}
                select
                onChange={handleChange2}
            >
                <MenuItem key={2} value={"mean"}>
                    Átlag
                </MenuItem>
                <MenuItem key={3} value={"median"}>
                    Medián
                </MenuItem>
            </TextField>
        </div>
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                width={800}
                height={300}
                data={[chartData]}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Legend />
                {category.map((option, index) => (
                    <Bar key={index} dataKey={titles[option]} fill={chartColors[index]}>
                        <LabelList
                            dataValues={[chartData]}
                            content={renderCustomizedLabel2}
                        />
                    </Bar>
                ))}
            </BarChart>
        </ResponsiveContainer>
    </>

    );
};

export default ScalesBar3;