import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { addFilledquestionnaire, deleteFilledquestionnaire, updateFilledquestionnaire } from '../../../store/actions/filledQuestionnaireActions';
import Container from '@material-ui/core/Container';
import useStyles from "../dashboard/styles";
import FilledStatsData from './FilledStatsData';
import FilledTable from './FilledTable';
import FilledStatsData2 from './FilledStatsData2';
import FilledStatsData3 from './FilledStatsData3';

const Filledquestionnaires = (params) => {
    const { status, menu, admins, institutions, questionnaires, actualInstitution, users, filledquestionnaires, authStatus } = params;
    const classes = useStyles();
    const { isEmpty, isLoaded } = authStatus;
    const convertArrayById = (aa) => {
        let a = [];
        aa && aa.forEach(p => {
            a[p.id] = p;
        })

        return a;
    }
    const dataLoadings =
        status &&
            status.requesting
            ? Object.keys(status.requesting).map(key => status.requesting[key])
            : [];
    const dataLoading = dataLoadings.includes(true);


    let institutionsArray = institutions ? convertArrayById(institutions) : [];
    let isAdmin = (admins && admins[0] && admins[0].isAdmin) ?? false;

    let field = 'status';
    let op = '==';
    let filter = 'evaluated';
    if (isAdmin && actualInstitution === 'Összes intézmény') {
        field = 'status';
        op = '==';
        filter = 'evaluated';
    } else {
        field = 'institutions';
        op = 'array-contains-any';
        filter = admins && admins[0] &&
            admins[0].institution && actualInstitution === 'Összes intézmény'
            ? admins[0].institution.slice(0, 10)
            : [actualInstitution];
    }

    useFirestoreConnect([
        {
            collection: "filledquestionnaires",
            where: [
                [field, op, filter],
                ['status', '==', 'evaluated'],
            ],
            orderBy: ['updatedAt', 'asc']
        },
        {
            collection: "users",
        },
        {
            collection: 'questionnaires',
            doc: '2XUJ9t7zegsFlbDvv4Er'
        },
    ]);

    return (
        <Container style={{}}>
            <div className={classes.responsiveContainer}>

                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        filledquestionnaires && questionnaires && institutions ?
                            <>
                                {menu === 'Tesztfelvételek' &&
                                    <FilledTable
                                        filledquestionnaires={filledquestionnaires}
                                        classes={classes}
                                        dataLoading={dataLoading}
                                        institutions={institutions}
                                        actualInstitution={actualInstitution}
                                        users={users}
                                    />
                                }
                                {menu === 'Statisztika' &&
                                    <FilledStatsData
                                        model={filledquestionnaires}
                                        classes={classes}
                                        questionnaire={questionnaires[0]}
                                        institutions={institutionsArray}
                                        actualInstitution={actualInstitution}
                                        dataLoading={dataLoading}
                                    />
                                }
                                {menu === 'Adatok' &&
                                    <FilledStatsData2
                                        model={filledquestionnaires}
                                        classes={classes}
                                        questionnaire={questionnaires[0]}
                                        institutions={institutionsArray}
                                        actualInstitution={actualInstitution}
                                        dataLoading={dataLoading}
                                    />
                                }
                                {menu === 'Intézmény' &&
                                    <FilledStatsData3
                                        model={filledquestionnaires}
                                        classes={classes}
                                        questionnaire={questionnaires[0]}
                                        institutions={institutionsArray}
                                        actualInstitution={actualInstitution}
                                        dataLoading={dataLoading}
                                        admins={admins && admins[0] ? admins[0].institution : []}
                                    />
                                }
                            </>
                            :
                            "Betöltés..."
                    ))}
            </div>
        </Container >
    );
};

const mapStateToProps = (state) => {
    return {
        filledquestionnaires: state.firestore.ordered.filledquestionnaires,
        questionnaires: state.firestore.ordered.questionnaires,
        authStatus: state.firebase.auth,
        users: state.firestore.ordered.users,
        actualInstitution: state.questionnaire.institution,
        institutions: state.firestore.ordered.institutions,
        admins: state.firestore.ordered.admins,
        menu: state.questionnaire.menu,
        status: state.firestore.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addFilledquestionnaire: (institution) => dispatch(addFilledquestionnaire(institution)),
        deleteFilledquestionnaire: (id) => dispatch(deleteFilledquestionnaire(id)),
        updateFilledquestionnaire: (institution, id) => dispatch(updateFilledquestionnaire(institution, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filledquestionnaires);
