import React from "react";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";
import { signOut } from "../../../store/actions/authActions";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ListAltOutlined, BarChart, Search, LocationCity } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions/actionTypes";
import { BottomNavigationAction, TableContainer } from "@material-ui/core";
import InstitutionSelect from "../dashboard/InstitutionSelect";
import { firebaseConfig } from "../../../config/firebaseConfig";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  active: {
    boxShadow: "rgba(0, 0, 0, 0.87) 4px 0px 0px inset",
    backgroundColor: "#a3b1ff",
  },
  inactive: {},
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  logo: {
    height: '30px',
    marginRight: '20px'
  },
  smallButton: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "30px", // Csökkentett szélesség mobilnál
      maxWidth: "60px",
      fontSize: "0.5rem", // Kisebb betűméret
      "& .MuiSvgIcon-root": {
        fontSize: "12px", // Kisebb ikonméret
      },
      padding: "4px 8px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "30px", // Csökkentett szélesség mobilnál
      maxWidth: "50px",
      fontSize: "0.4rem", // Kisebb betűméret
      "& .MuiSvgIcon-root": {
        fontSize: "10px", // Kisebb ikonméret
      },
      padding: "4px 8px",
    },
  },
  eee: {
    [theme.breakpoints.down("sm")]: {

      maxWidth: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "50px",

    },
  }
}));

const Navbar = (params) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { authStatus, profile, signOut, menu, status, actualInstitution } = params;
  const { isEmpty, isLoaded } = authStatus;
  const history = useHistory();
  const classes = useStyles();

  const handleSubmitFilledQuestionnaires = () => {
    dispatch({ type: actions.SET_MENU, payload: "Tesztfelvételek" });
    if (location.pathname != "/") {
      console.log("PATH", location.pathname);
      history.push(`/`);
    }
  };

  const handleSubmitCharts = () => {
    dispatch({ type: actions.SET_MENU, payload: "Statisztika" });
    if (location.pathname != "/") {
      console.log("PATH", location.pathname);
      history.push(`/`);
    }
  };
  const handleSubmitData = () => {
    dispatch({ type: actions.SET_MENU, payload: "Adatok" });
    if (location.pathname != "/") {
      console.log("PATH", location.pathname);
      history.push(`/`);
    }
  };

  const handleSubmitInst = () => {
    dispatch({ type: actions.SET_MENU, payload: "Intézmény" });
    if (location.pathname != "/") {
      console.log("PATH", location.pathname);
      history.push(`/`);
    }
  };

  const signout2 = () => {
    signOut();
  };

  const dataLoadings =
    status && status.requesting
      ? Object.keys(status.requesting).map((key) => status.requesting[key])
      : [];

  const dataLoading = dataLoadings.includes(true);

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {})}
      >
        <Toolbar>
          <Typography color="inherit" className={classes.title}>
            <>
              {firebaseConfig.projectId === "flutter-ef6d3" ? '[Teszt]' : ''}
              <BottomNavigationAction
                showLabel
                className={clsx(
                  classes.smallButton,
                  menu === "Tesztfelvételek" ? classes.active : classes.inactive
                )}
                label="Kitöltések"
                icon={<ListAltOutlined />}
                onClick={() => handleSubmitFilledQuestionnaires()}
              />
              <BottomNavigationAction
                showLabel
                className={clsx(
                  classes.smallButton,
                  menu === "Statisztika" ? classes.active : classes.inactive
                )}
                label="Diagram"
                icon={<BarChart />}
                onClick={() => handleSubmitCharts()}
              />
              <BottomNavigationAction
                showLabel
                className={clsx(
                  classes.smallButton,
                  menu === "Adatok" ? classes.active : classes.inactive
                )}
                label="Adatok"
                icon={<Search />}
                onClick={() => handleSubmitData()}
              />
              {actualInstitution === "Összes intézmény" &&
                <BottomNavigationAction
                  showLabel
                  className={clsx(
                    classes.smallButton,
                    menu === "Intézmény" ? classes.active : classes.inactive
                  )}
                  label="Intézmény"
                  icon={<LocationCity />}
                  onClick={() => handleSubmitInst()}
                />
              }


            </>
          </Typography>
          {dataLoading && (
            <img
              className={classes.logo}
              src={"ajax-loader5.gif"}
            />
          )}

          <InstitutionSelect
            classes={classes}
          />
          {isLoaded &&
            (isEmpty ? (
              <SignedOutLinks></SignedOutLinks>
            ) : (
              <SignedInLinks
                signOut={signout2}
                profile={profile}
              ></SignedInLinks>
            ))}
        </Toolbar>
      </AppBar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authStatus: state.firebase.auth,
    profile: state.firebase.profile,
    menu: state.questionnaire.menu,
    status: state.firestore.status,
    actualInstitution: state.questionnaire.institution,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
