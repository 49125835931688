import React from 'react';
import FSPTable from '../../common/FSPTable/FSPTable';

const convertArrayById = (aa) => {
    let a = [];
    aa && aa.forEach(p => {
        a[p.id] = p;
    })

    return a;
}

const FilledTable = ({
    filledquestionnaires,
    classes,
    dataLoading,
    institutions,
    actualInstitution,
    users
}) => {
    let institutionsArray = institutions ? convertArrayById(institutions) : [];
    let usersArray = users ? convertArrayById(users) : [];
    const form = {
        titles: {
            index: 'Tesztkitöltések',
            create: 'Új tesztkitöltés',
            update: 'Tesztkitöltés módosítása',
        },
        operations: {
            create: false,
            delete: false,
            update: false
        },
        rowsPerPage: 25,
        keyField: 0,
        indexFields: [5, 3, 4],
        fields: [
            {
                id: 0,
                name: 'id',
                required: true,
                helper: 'id',
                q2: 'id',
                label: 'id',
                type: 2,
            },
            {
                id: 1,
                required: true,
                name: 'title',
                helper: 'Teszt',
                q2: 'Teszt',
                label: 'Teszt',
                type: 2,
            },
            {
                id: 2,
                required: true,
                name: 'status',
                helper: 'Státusz',
                q2: 'Státusz',
                label: 'Státusz',
                type: 2,
            },
            {
                id: 3,
                required: true,
                name: 'authorId',
                helper: 'Felhasználó',
                q2: 'Felhasználó',
                label: 'Felhasználó',
                type: 3,
                values: users,
                valuesArray: usersArray,
                values_label: 'email',
                values_key: 'id',
            },
            {
                id: 4,
                required: true,
                name: 'institutions',
                helper: 'Intézmény',
                q2: 'Intézmény',
                label: 'Intézmény',
                type: 5,
                values: institutions,
                valuesArray: institutionsArray,
                values_label: 'name',
                values_key: 'id',
                model: 'institutions',
                modelId: 'id',
                modelLabel: 'name'
            },
            {
                id: 5,
                required: true,
                name: 'updatedAt',
                helper: 'Dátum',
                q2: 'Dátum',
                label: 'Dátum',
                type: 6,
            },
        ]
    };
    return (
        <FSPTable
            model={filledquestionnaires}
            classes={classes}
            form={form}
            dataLoading={dataLoading}
            meta={{ institutions: institutions }}
            institutions={institutions}
            actualInstitution={actualInstitution}
        />
    );
};

export default FilledTable;