import React from 'react';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { addFilledquestionnaire, deleteFilledquestionnaire, updateFilledquestionnaire } from '../../../store/actions/filledQuestionnaireActions';
import Container from '@material-ui/core/Container';
import useStyles from "../dashboard/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import FSPTable from '../../common/FSPTable/FSPTable';
import FilledStats from './FilledStats';

const Filledquestionnaires = ({ menu, admins, institutions, questionnaires, actualInstitution, users, filledquestionnaires, authStatus, addFilledquestionnaire, updateFilledquestionnaire, deleteFilledquestionnaire }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isEmpty, isLoaded } = authStatus;
    const convertArrayById = (aa) => {
        let a = [];
        aa && aa.forEach(p => {
            a[p.id] = p;
        })

        return a;
    }
    let usersArray = users ? convertArrayById(users) : [];
    let institutionsArray = institutions ? convertArrayById(institutions) : [];
    let isAdmin = (admins && admins[0].isAdmin) ?? false;

    let field = 'status';
    let op = '==';
    let filter = 'evaluated';
    if (isAdmin && actualInstitution === 'Összes intézmény') {
        field = 'status';
        op = 'in';
        filter = ['in progress', 'personal', 'gdpr', 'evaluated', ''];
    } else {
        field = 'institutions';
        op = 'array-contains-any';
        filter = admins && admins[0] &&
            admins[0].institution && actualInstitution === 'Összes intézmény'
            ? admins[0].institution.slice(0, 10)
            : [actualInstitution];
    }

    const form = {
        titles: {
            index: 'Minden tesztkitöltés',
            create: 'Új tesztkitöltés',
            update: 'Tesztkitöltés módosítása',
        },
        operations: {
            create: false,
            delete: false,
            update: false
        },
        rowsPerPage: 25,
        keyField: 0,
        indexFields: [5, 0, 3, 4, 6],
        fields: [
            {
                id: 0,
                name: 'id',
                required: true,
                helper: 'id',
                q2: 'id',
                label: 'id',
                type: 2,
            },
            {
                id: 1,
                required: true,
                name: 'title',
                helper: 'Teszt',
                q2: 'Teszt',
                label: 'Teszt',
                type: 2,
            },
            {
                id: 2,
                required: true,
                name: 'status',
                helper: 'Státusz',
                q2: 'Státusz',
                label: 'Státusz',
                type: 2,
            },
            {
                id: 3,
                required: true,
                name: 'authorId',
                helper: 'Felhasználó',
                q2: 'Felhasználó',
                label: 'Felhasználó',
                type: 3,
                values: users,
                valuesArray: usersArray,
                values_label: 'email',
                values_key: 'id',
            },
            {
                id: 4,
                required: true,
                name: 'institutions',
                helper: 'Intézmény',
                q2: 'Intézmény',
                label: 'Intézmény',
                type: 5,
                values: institutions,
                valuesArray: institutionsArray,
                values_label: 'name',
                values_key: 'id',
                model: 'institutions',
                modelId: 'id',
                modelLabel: 'name'
            },
            {
                id: 5,
                required: true,
                name: 'createdAt',
                helper: 'Dátum',
                q2: 'Dátum',
                label: 'Dátum',
                type: 6,
            },
            {
                id: 6,
                required: true,
                name: 'status',
                helper: 'Státusz',
                q2: 'Státusz',
                label: 'Státusz',
                type: 2,
            },
        ]
    };
    useFirestoreConnect([
        {
            collection: "filledquestionnaires",
            where: [
                // ['institutions', 'array-contains-any',
                //     admins && admins[0] && admins[0].institution && actualInstitution === 'Összes intézmény'
                //         ? admins[0].institution.slice(0,9)
                //         : [actualInstitution]]
                [field, op, filter],
                // ['status', '!=', 'evaluated'],
                // ['status', '!=', 'in progress'],
            ],
            orderBy: ['createdAt', 'asc'],
            storeAs: 'inProgress'
        },
        {
            collection: "users",
            // doc: authStatus.uid,
        },
        {
            collection: 'questionnaires',
            doc: '2XUJ9t7zegsFlbDvv4Er'
        },
        // {
        //     collection: "institutions",
        //     storeAs: 'institutions'
        // }
        // {
        //     collection: 'filledquestionnaires',
        //     where: [
        //         ['docId', '==', '2XUJ9t7zegsFlbDvv4Er'],
        //     ],
        // }
    ]);

    const createElement = (newMeasure) => {
        if (typeof newMeasure.id === 'undefined') {
            addFilledquestionnaire(newMeasure);
        } else {
            updateFilledquestionnaire(newMeasure, newMeasure.id)
        }
    }

    const deleteElement = (newMeasure) => {
        deleteFilledquestionnaire(newMeasure);
    }

    // dispatch({ type: actions.SET_MENU, payload: 'Tesztfelvételek' })

    return (
        <Container className={classes.content}>
            <div>
                {isLoaded &&
                    (isEmpty ? (
                        <Redirect to='/signin' />
                    ) : (
                        filledquestionnaires && questionnaires && institutions ?
                            <>
                                <FSPTable
                                    model={filledquestionnaires}
                                    classes={classes}
                                    // createElement={(measure) => createElement(measure)}
                                    // deleteElement={(measure) => deleteElement(measure)}
                                    form={form}
                                    meta={{ institutions: institutions }}
                                />
                            </>
                            :
                            "Loading..."
                    ))}
            </div>
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        filledquestionnaires: state.firestore.ordered.inProgress,
        questionnaires: state.firestore.ordered.questionnaires,
        authStatus: state.firebase.auth,
        users: state.firestore.ordered.users,
        actualInstitution: state.questionnaire.institution,
        institutions: state.firestore.ordered.institutions,
        admins: state.firestore.ordered.admins,
        menu: state.questionnaire.menu,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addFilledquestionnaire: (institution) => dispatch(addFilledquestionnaire(institution)),
        deleteFilledquestionnaire: (id) => dispatch(deleteFilledquestionnaire(id)),
        updateFilledquestionnaire: (institution, id) => dispatch(updateFilledquestionnaire(institution, id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filledquestionnaires);
