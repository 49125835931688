import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

/*The apiKey for the firebase project, should be exposed so that the users can interact with the firebase project. The apiKey in this configuration snippet just identifies your Firebase project on the Google server ->  https://stackoverflow.com/questions/37482366/is-it-safe-to-expose-firebase-apikey-to-the-public*/
//Oktatás Demo
// export const firebaseConfig = {
//   apiKey: "AIzaSyAWEMocRF-fP1qUEB7dijuBKG3sEOYawd0",
//   authDomain: "flutter-ef6d3.firebaseapp.com",
//   projectId: "flutter-ef6d3",
//   storageBucket: "flutter-ef6d3.firebasestorage.app",
//   messagingSenderId: "183889492229",
//   appId: "1:183889492229:web:e0e4b4ccd9bd5cd869df6c",
//   measurementId: "G-M0XYSN9NPQ"
// };
//Oktatás 2030
export const firebaseConfig = {
  apiKey: "AIzaSyAQzDuTQqvKi_fwii0-QGFUEo2F1XiNIFU",
  authDomain: "teszt-296513.firebaseapp.com",
  databaseURL: "https://teszt-296513.firebaseio.com",
  projectId: "teszt-296513",
  storageBucket: "teszt-296513.appspot.com",
  messagingSenderId: "965438652085",
  appId: "1:965438652085:web:8b38da38efe796dc8b88fc"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
