import React from "react";
import { useInView } from "react-intersection-observer";

const InfiniteScroll = ({ loadMore }) => {
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 1.0, // Akkor triggerel, ha a teljes érzékelő terület látszik
    });

    React.useEffect(() => {
        if (inView) {
            loadMore();
        }
    }, [inView, loadMore]);

    return (
        <div
            ref={ref}
            style={{
                height: "50px",
                width: "100%",
                backgroundColor: "transparent",
            }}
        />
    );
};

export default InfiniteScroll;
