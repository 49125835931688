import React, { useEffect, useState } from 'react';
import { MenuItem, TextField } from "@material-ui/core";
import FilledStatsTable from '../FilledStatsTable';
import groupingData from '../FilledStatsUtility';
import { allFields, leaders } from '../FilledStatsConsts';

const FilledTable = ({
    title,
    classes,
    data,
    base,
    // groupByYear,
    // groupByPosition,
    // groupByLeader,
    questionnaire,
}) => {

    const [chartData, setChartData] = useState("allData");
    const [show, setShow] = useState("mean");
    const [groupByYear, setGroupByYear] = useState([]);
    const [groupByPosition, setGroupByPosition] = useState([]);
    const [groupByLeader, setGroupByLeader] = useState([]);

    useEffect(() => {
        let groupByYear = groupingData(
            base,
            "years_txt",
            allFields
            //  leaders
        );
        console.log('groupByYear', groupByYear);
        setGroupByYear(groupByYear);

        let groupByPosition = groupingData(
            base,
            "position_txt",
            allFields
            //  leaders
        );
        console.log('groupByPosition', groupByPosition);
        setGroupByPosition(groupByPosition);
        let groupByLeader = groupingData(
            base,
            "position_txt",
            allFields,
            leaders
        );
        setGroupByLeader(groupByLeader);
    }, [base]);

    const handleChangeEma12c = (event, newAlignment) => {
        // console.log(event.target);
        // console.log(newAlignment);
        setChartData(event.target.value);
    }

    const handleChangeShow = (event, newAlignment) => {
        // console.log(event.target);
        // console.log(newAlignment);
        setShow(event.target.value);
    }
    // console.log('data1111',data);
    // console.log('groupByYear111',groupByYear);
    return (
        <div>
            <h3>{title}</h3>
            Csoportosítás:
            <TextField
                required
                id={"distribution"}
                value={chartData}
                name={"distribution"}
                select
                className={classes.selectfilter}
                // label={"Laboratory"}
                onChange={handleChangeEma12c}
                // InputProps={{ disableUnderline: true }}
                InputProps={{
                    classes: {
                        input: classes.statSelect,
                    },
                }}
            //  helperText={"Select lab"}
            >
                <MenuItem key={1} value={"allData"}>
                    Minden kitöltő
                </MenuItem>
                <MenuItem key={2} value={"groupByYear"}>
                    Évek
                </MenuItem>
                <MenuItem key={3} value={"groupByPosition"}>
                    Pozíció
                </MenuItem>
                <MenuItem key={3} value={"groupByLeader"}>
                    Vezető
                </MenuItem>
            </TextField>
            Adat:
            <TextField
                required
                id={"distribution2"}
                value={show}
                name={"distribution2"}
                select
                className={classes.selectfilter}
                // label={"Laboratory"}
                onChange={handleChangeShow}
                // InputProps={{ disableUnderline: true }}
                InputProps={{
                    classes: {
                        input: classes.statSelect,
                    },
                }}

            //  helperText={"Select lab"}
            >
                <MenuItem key={1} value={"sum"}>
                    Összeg
                </MenuItem>
                <MenuItem key={2} value={"mean"}>
                    Átlag
                </MenuItem>
                <MenuItem key={3} value={"median"}>
                    Medián
                </MenuItem>
            </TextField>
            <FilledStatsTable
                classes={classes}
                data={chartData}
                show={show}
                allData={data}
                groupByYear={groupByYear}
                groupByPosition={groupByPosition}
                groupByLeader={groupByLeader}
                questionnaire={questionnaire}
            />
        </div>
    );
};

export default FilledTable;