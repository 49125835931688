import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Add } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import FSPTableRow from './FSPTableRow';
import FSPTableFooter from './FSPTableFooter';
import FSPTableHeader from './FSPTableHeader';
import MyLoader from './FSPTableLoader';
import ActualInstitute from '../../UI/filled/ActualInstitute';

class FSPTable extends Component {
    constructor(props) {
        super();
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleStop = this.handleStop.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeArray = this.handleChangeArray.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleFormUpdate = this.handleFormUpdate.bind(this);

        this.state = {
            data: [],
            page: 0,
            rowsPerPage: props.form.rowsPerPage || 5,
            val: {
                uid: props.uid ? props.uid : null,
                id: null,
                name: null,
            },
            form: {
                date: new Date('2015-01-01'),
            },
            errors: {}
        }
    }

    handleChange(e) {
        console.log(this.state.val);
        let n = { ...this.state.val };
        n[e.target.name] = e.target.value;
        this.setState({
            val: { ...n }
        });
        let ee = this.state.errors;
        ee[e.target.name] = e.target.value ? '' : 'Nem lehet üres';
        this.setState({
            errors: ee
        });
    }

    handleChangeArray(name, value) {
        console.log(name);
        console.log(value);
        console.log(this.state.val);
        let n = { ...this.state.val };
        let old = this.state.val[name] ?? []
        old = value;
        n[name] = old;
        this.setState({
            val: { ...n }
        });
        let ee = this.state.errors;
        ee[name] = value.length > 0 ? '' : 'Nem lehet üres';
        this.setState({
            errors: ee
        });

    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value) });
        this.setState({ page: 0 });
    };

    handleCreate = (e) => {
        console.log(e);
        this.setState({
            val: []
        });
        this.setState({ open: true });
    }

    handleEdit = (e) => {
        console.log(e);
        this.setState({
            val: e
        });
        this.setState({ open: true });
    }

    handleFormUpdate = (e) => {
        console.log(e);
        console.log(this.state.val)
        let er = [];
        let err = 0;
        let x = this.props.form.fields.map((field, index) => {
            console.log(index, field);
            console.log(this.state.val[field.name]);
            // er[field.name] = this.state.val[field.name] ? '' : 'Nem lehet üres';
            // err = this.state.val[field.name] || !field.required ? err : err + 1;
            // return this.state.val[field.name] ? true : false
            // let form = form0[0];
            if (field.type === 5) {
                console.log(this.state.val[field.name]);
                console.log(this.state.val[field.name].length);
                er[field.name] = this.state.val[field.name] && this.state.val[field.name].length > 0 ? '' : 'Nem lehet üres';
                err = (this.state.val[field.name] && this.state.val[field.name].length > 0) || !field.required ? err : err + 1;
                console.log(err);
                return this.state.val[field.name] ? true : false
            } else {
                er[field.name] = this.state.val[field.name] ? '' : 'Nem lehet üres';
                err = this.state.val[field.name] || !field.required ? err : err + 1;
                return this.state.val[field.name] ? true : false
            }
        })
        this.setState({
            errors: er
        });
        console.log(er);
        if (err === 0) {
            console.log('MENTÜNK', err);
            this.props.createElement(e);
            this.setState({ open: false });
        } else {
            console.log('NEM', err);
        }
    }

    handleStop = (e) => {
        this.setState({ open: false });
    }
    render() {
        const { classes, model, form, loading, meta, actualInstitution, institutions } = this.props;
        return (
            <div>


                <h1>{form.titles.index}</h1>
                <ActualInstitute
                    institutionsArray={institutions}
                    actualInstitution={actualInstitution}
                />
                {form.operations && form.operations.create &&
                    <Box p={1}>
                        <Button
                            type='submit'
                            // variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => { this.handleCreate(); }}
                        >
                            <Add />{form.titles.create}
                        </Button>
                    </Box>
                }

                <TableContainer
                    // style={{ border: "1px solid rgba(0,0,0,0.2)", padding: 4 }}
                    className={classes.table}
                    component={Paper}>
                    <Table size="small" classes={{ root: classes.customTable }} aria-label="a dense table">
                        <FSPTableHeader
                            form={form}
                        />
                        <TableBody>
                            {loading ? <MyLoader /> :
                                model && model.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((element, index) => (
                                        <FSPTableRow
                                            key={index}
                                            index={index}
                                            element={element}
                                            classes={classes}
                                            uid={element.index}
                                            handleEdit={this.handleEdit}
                                            deleteElement={this.props.deleteElement}
                                            form={form}
                                            meta={meta}
                                        />
                                    ))
                            }
                        </TableBody>
                        <FSPTableFooter
                            count={this.props.model.length}
                            rowsPerPage={this.state.rowsPerPage}
                            model={this.props.model}
                            page={this.state.page}
                            handleChangePage={(e, a) => this.handleChangePage(e, a)}
                            handleChangeRowsPerPage={(e) => this.handleChangeRowsPerPage(e)}
                        />
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default FSPTable;