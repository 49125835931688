import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import {
  leaders, everybody, allFields, subscales, scales,
  leadersCategories, years, positions, leadersCategories0,
  fieldsScales, fieldsSubscales
} from "./FilledStatsConsts";
import { createBaseData, groupingData } from "./FilledStatsUtility";
import InfiniteScrollList from "./charts/InfiniteScrollList";
import ActualInstitute from './ActualInstitute';

const FilledStatsData = ({
  model,
  classes,
  questionnaire,
  institutions,
  dataLoading,
  actualInstitution
}) => {
  const [base, setBase] = useState();
  const [allData, setAllData] = useState();
  const [dataForAllChart, setDataForAllChart] = useState();
  const [charts, setCharts] = useState();

  const calculateData = (model, classes, questionnaire, institutions, dataLoading) => {
    if (!dataLoading && model && questionnaire && institutions) {
      let base = [];
      let allData = [];
      let dataForAllChart = [];
      base = createBaseData(model, questionnaire, institutions);

      allData = groupingData(
        base,
        "position_txt",
        allFields,
        everybody
      );

      dataForAllChart = [
        {
          name: "Összes",
          index:
            allData && allData.mean && allData.mean.everybody
              ? allData.mean.everybody.percent
              : 0,
        },
      ];
      setBase(base);
      setAllData(allData);
      setDataForAllChart(dataForAllChart);
      setCharts([
        {
          type: "TszkIndex",
          title: "TSZK Index",
          data: dataForAllChart,
          height: 85,
          yAxisWidth: 50,
          dataKey: "index"
        },
        {
          type: "TszkBarChart2",
          title: "Skálák",
          data: base,
          // categoryField: "years_txt",
          category: fieldsScales,
          height: 160,
        },
        {
          type: "ScalesBar3",
          category: scales,
          data: allData,
          stat: 'mean'
        },
        {
          type: "TszkBarChart2",
          title: "Alskálák",
          data: base,
          // categoryField: "years_txt",
          category: fieldsSubscales
        },
        {
          type: "ScalesBar3",
          category: subscales,
          data: allData,
          stat: 'mean'
        },
        {
          type: "FilledPieChart",
          data: allData,
        },
        {
          type: "TszkBarChart2",
          title: "Mióta dolgozik az adott munkahelyen?",
          data: base,
          categoryField: "years_txt",
          category: years
        },
        {
          type: "TszkBarChart2",
          title: "Betöltött pozíció",
          data: base,
          categoryField: "position_txt",
          category: positions
        },
        {
          type: "TszkBarChart2",
          title: "A vezető és nem vezető beosztásban lévők vélekedésének összehasonlítása",
          data: base,
          categoryField: "position_txt",
          category: leadersCategories0,
          subgroup: leaders,
          height: 120,
          yAxisWidth: 50
        },
        {
          type: "GeneralChart",
          title: `A vezető és nem vezető beosztásban lévők vélekedésének összehasonlítása 1. (Skálák)`,
          data: base,
          category: scales,
          series: leadersCategories,
          seriesGrouping: leaders,
          questionnaire: questionnaire,
          rotate: true,
        },
        {
          type: "GeneralChart",
          title: `A vezető és nem vezető beosztásban lévők vélekedésének összehasonlítása 2. (AlSkálák)`,
          data: base,
          category: subscales,
          series: leadersCategories,
          seriesGrouping: leaders,
          questionnaire: questionnaire,
          rotate: true,
        },
        {
          type: "GeneralChart",
          title: `A vezető és nem vezető beosztásban lévők vélekedésének összehasonlítása 3. (Skálák 2.)`,
          data: base,
          category: scales,
          series: leadersCategories,
          seriesGrouping: leaders,
          questionnaire: questionnaire,
          rotate: false,
        },
        {
          type: "GeneralChart",
          title: `A vezető és nem vezető beosztásban lévők vélekedésének összehasonlítása 4. (Alskálák 2.)`,
          data: base,
          category: subscales,
          series: leadersCategories,
          seriesGrouping: leaders,
          questionnaire: questionnaire,
          rotate: false,
        },
        // {
        //   type: "FilledTable",
        //   title: `Csoportosítás`,
        //   data: allData,
        //   base: base,
        //   classes: classes,
        //   questionnaire: questionnaire.questionnaire,
        // }
      ]);
    }
  };

  useEffect(() => {
    calculateData(
      model, classes, questionnaire, institutions, dataLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  return (
    <>
      {charts && base && allData &&
        dataForAllChart &&
        (
          <>
            <h1>Statisztika</h1>
            <ActualInstitute
              institutionsArray={institutions}
              actualInstitution={actualInstitution}
            />
            <h3>Kitöltők száma: {base ? base.length : "0"}</h3>
            <InfiniteScrollList
              items={charts}
            />
            <CSVLink data={base}>CSV export</CSVLink>
          </>
        )}
    </>
  );
};

export default FilledStatsData;