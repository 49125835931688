import React, { useEffect, useState } from 'react';
import {
    Cell,
    Legend,
    PieChart,
    Pie,
    ResponsiveContainer
} from "recharts";

import { chartColors, subscales, titles } from '../FilledStatsConsts';

const FilledPieChart = ({ data }) => {
    const [chartData, setChartData] = useState();

    useEffect(() => {
        let subScaleChartData = [];
        subscales.forEach((key) => {
            let x =
                data && data.mean && data.mean.everybody
                    ? data.mean.everybody[key]
                    : 0;
            subScaleChartData.push({ name: titles[key], value: parseInt(x) });
        });
        setChartData(subScaleChartData);
        // console.log('FilledPieChart data', data);
    }, [data]);


    return (
        <ResponsiveContainer width="100%" height={400}>
            <PieChart
                width={800}
                height={400}
            >
                <Pie
                    width={800}
                    height={400}
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    label
                    labelLine={false}
                    // label={renderCustomizedLabel}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {chartData && chartData.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={chartColors[index % chartColors.length]}
                        />
                    ))}
                    {/* <LabelList dataValues={a} /> */}
                </Pie>
                <Legend />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default FilledPieChart;