import React from 'react';

const ActualInstitute = ({ institutionsArray, actualInstitution }) => {
    return (
        <h2>
            {actualInstitution === 'Összes intézmény' ? actualInstitution :
                institutionsArray && actualInstitution && institutionsArray[actualInstitution] &&
                institutionsArray[actualInstitution].name}
        </h2>
    );
};

export default ActualInstitute;