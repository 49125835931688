import React, { useEffect, useState } from 'react';
import { everybody, allFields, fieldsStat } from './FilledStatsConsts';
import TszkIndex from './TszkIndex';
import groupingData from './FilledStatsUtility';
import { grouping7, grouping6 } from './charts/Utility';
import { MenuItem, TextField } from '@material-ui/core';
import Chart1 from './Chart1';

const TszkBarChart2 = (params) => {
    const { title, data, dataKey, height, yAxisWidth, categoryField,
        category, subgroup, order = "none", rotate = false } = params;
    const [chartData, setChartData] = useState([]);
    const [filter, setFilter] = useState("percent");
    const [stat, setStat] = useState("mean");
    const [order2, setOrder2] = useState(order);

    let categories = {};
    if (category && Array.isArray(category)) {
        category.forEach(element => {
            categories[element.value] = element.label
        });
    }
    useEffect(() => {
        if (data && categoryField) {
            const groupByYear = groupingData(
                data,
                categoryField,
                // allFields,
                [filter],
                subgroup
                //  leaders
            );
            // console.log('groupByYear', groupByYear);
            let dataForYearChart = [];
            if (category && groupByYear.sum) {
                dataForYearChart = grouping6({
                    category: category,
                    baseData: groupByYear,
                    field: filter,
                    stat: stat
                })

            }
            // console.log('dataForYearChart', dataForYearChart);
            setChartData(dataForYearChart);
        } else {
            if (data) {
                const allData = groupingData(
                    data,
                    "position_txt",
                    allFields,
                    everybody
                );
                // console.log('allData', allData);
                const dataForAllChart = grouping7({
                    category: category,
                    baseData: allData,
                    field: "everybody",
                    stat: stat
                })
                // console.log('dataForAllChart', dataForAllChart);
                setChartData(dataForAllChart);
            }
        }

    }, [category, data, filter, stat]);

    const handleChange = (e) => {
        e.preventDefault();
        setFilter(e.target.value);
    };

    const handleChange2 = (e) => {
        e.preventDefault();
        setStat(e.target.value);
    };
    const handleChange3 = (e) => {
        e.preventDefault();
        setOrder2(e.target.value);
    };

    return (
        <>
            <h2>{title}</h2>
            {categoryField &&
                <>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <span>Rendezés</span>
                        <TextField
                            required
                            id={"order1"}
                            value={order2}
                            name={"order1"}
                            select
                            onChange={handleChange3}
                        >
                            <MenuItem key={2} value={"none"}>
                                Nincs
                            </MenuItem>
                            <MenuItem key={3} value={"name"}>
                                Név
                            </MenuItem>
                            <MenuItem key={3} value={"value"}>
                                Érték
                            </MenuItem>
                        </TextField>
                    </div>
                    <Chart1
                        chartData={chartData}
                        rotate={rotate}
                        categories={categories}
                        dataKey={dataKey}
                        order={order2}
                    />
                </>
            }
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {categoryField &&
                    <>
                        <span>Állítások</span>
                        <TextField
                            required
                            id={"distribution"}
                            value={filter}
                            name={"distribution"}
                            select
                            onChange={handleChange}
                        >
                            {Object.keys(fieldsStat).map((i, index) => {
                                return (
                                    <MenuItem key={`cell0-${index}`} value={i}>
                                        {fieldsStat[i]}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </>
                }

                <span>Adat:</span>
                <TextField
                    required
                    id={"distribution2"}
                    value={stat}
                    name={"distribution2"}
                    select
                    onChange={handleChange2}
                >
                    <MenuItem key={2} value={"mean"}>
                        Átlag
                    </MenuItem>
                    <MenuItem key={3} value={"median"}>
                        Medián
                    </MenuItem>
                </TextField>
                <span>Rendezés</span>
                <TextField
                    required
                    id={"order1"}
                    value={order2}
                    name={"order1"}
                    select
                    onChange={handleChange3}
                >
                    <MenuItem key={2} value={"none"}>
                        Nincs
                    </MenuItem>
                    <MenuItem key={3} value={"name"}>
                        Név
                    </MenuItem>
                    <MenuItem key={3} value={"value"}>
                        Érték
                    </MenuItem>
                </TextField>
            </div>
            <TszkIndex
                height={height}
                yAxisWidth={yAxisWidth}
                title={`TSZK Index: ${fieldsStat[filter]}(${stat === 'mean' ? 'átlag' : 'medián'})`}
                data={chartData}
                order={order2}
            />
        </>
    );
};

export default TszkBarChart2;