import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';

import Navbar from './components/UI/navigation/Navbar';
import SignIn from './components/UI/auth/SignIn';
import SignUp from './components/UI/auth/SignUp';
import Filledquestionnaires from './components/UI/filled/Filledquestionnaires';
import InProgressQuestionnaires from './components/UI/filled/InProgressQuestionnaires';

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[500],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={outerTheme}>
      <Router
        basename={'#'}
      >
        <Switch>
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/signup' component={SignUp} />
          <Route path={["/", "/users", "/admins", "/sampling", "/edit/:id", "/evaluation/:id", "/filled/:id", "/statistics", "/permissions"]}>
            <div style={{ flexGrow: 1, alignItems: 'center' }}>
              <Navbar />
              <Switch>
                <Route exact path='/' component={Filledquestionnaires} />
                <Route exact path='/statistics' component={InProgressQuestionnaires} />
              </Switch>
            </div>
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
